/**
 * @minxis countDown 倒计时混入
 */
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class countDown extends Vue {
    // 是否获取验证码
    public isQueryCode: boolean = false;
    // 计时器
    public timer: any = null;
    // 倒计时
    public time: number = 60;


    // 获取验证码 根据混入的组件定义
    queryCode(): void {

    }
    // 再次获取验证码
    againQueryCode() {
        if (this.isQueryCode) return false;
        this.queryCode();
    }
    // 再次获取计时
    againQueryTime(): void {
        this.timer = setInterval(() => {
            this.time--;
            console.log(this.time);
            if (this.time <= 0) {
                this.closeTimer();
                this.time = 60;
            }
        }, 1000);
    }
    // 关闭计时
    closeTimer(): void {
        clearInterval(this.timer);
        this.isQueryCode = false;
        console.log("计时关闭");
    }

    // 组件销毁移出计时
    beforeDestroy(): void {
        this.closeTimer();
    }
}