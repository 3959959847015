
import { Component, Prop, Watch, Ref } from "vue-property-decorator";
import { Steps } from "ant-design-vue";
import { mixins } from "vue-class-component";
import CountDown from "./components/mixin/countDown";

import { canvasWM } from "@/common/utils/util";

interface TextVerify {
  phone: string | number;
  textCode: string | number;
}
interface EnterpriseInformation {
  name: string;
  industry: string | undefined;
  region: string | undefined;
  contacts: string;
}

@Component({
  name: "EnterpriseRegister",
  components: {
    [Steps.name]: Steps,
    [Steps.Step.name]: Steps.Step,
  },
  filters: {
    //局部过滤器
  },
})
export default class EnterpriseRegister extends mixins(CountDown) {
  // 步骤进度
  public step: number = 0;
  // 表单数据
  public formData: TextVerify = {
    phone: "",
    textCode: "",
  };
  public formData2: EnterpriseInformation = {
    name: "",
    industry: undefined,
    region: undefined,
    contacts: "",
  };
  // 验证规则
  public rules: any = {
    phone: [
      { required: true, message: "请输入手机号", trigger: "blur" },
      {
        pattern:
          /^(13[0-9]|14[5|7]|15[0|1|2|3|4|5|6|7|8|9]|18[0|1|2|3|5|6|7|8|9])\d{8}$/,
        message: "请输入正确手机号",
      },
    ],
    textCode: [{ required: true, message: "请输入验证码", trigger: "blur" }],
  };
  public rules2: any = {
    name: [{ required: true, message: "请输入企业名称", trigger: "blur" }],
    industry: [{ validator: this.verifyIndustryRegion }],
    contacts: [
      { required: true, message: "请输入企业联系人姓名", trigger: "blur" },
    ],
  };

  //返回
  back(): void {
    this.step === 1 ? (this.step = 0) : this.$router.back();
  }
  // 获取验证码
  queryCode(): void {
    console.log("获取验证码");
    this.isQueryCode = true;
    // mixins混入方法
    this.againQueryTime();
  }
  // 下一步
  next(): void {
    this.ruleForm.validate((valid: boolean) => {
      if (valid) {
        this.step = 1;
        this.closeTimer();
      }
    });
  }
  // 验证行业和地区
  verifyIndustryRegion(rule: any, value: string, callback: any): void {
    if (!this.formData2.industry) {
      callback(new Error("请选择行业"));
    } else if (!this.formData2.region) {
      callback(new Error("请选择地区"));
    } else {
      callback();
    }
  }
  // 注册
  register(): void {
    this.ruleForm2.validate((valid: boolean) => {
      if (valid) {
      }
    });
  }
  //props定义 需要依赖装饰器 @Prop({ default: '默认值', type: '数据类型' })
  //@Prop()
  //计算属性
  //get computedMsg(): string {
  //  return ''
  //}
  //数据监听 Watch('监听数据', { deep: Boolean | 深度监听, immediate: Boolean | 初始化调用 })
  //@Watch('')
  //ref 引用元素 Ref('ref')
  @Ref("ruleForm")
  ruleForm!: any;
  @Ref("ruleForm2")
  ruleForm2!: any;
  @Ref("shade")
  shade!: any;
  //创建前钩子函数
  created(): void {}
  mounted() {
    canvasWM({
      container: this.shade,
      width: "520px",
      height: "540px",
    });
  }
}
